// 公用
import commonServiceIcon from './images/common/service_icon.png'
import commonCopyIcon from './images/common/copy_icon.png'
import commonTransIcon from './images/common/trans_icon.png'
import commonArrowDownIcon from './images/common/arrow_down_icon.png'
import commonColorArrowDownIcon from './images/common/arrow_down_icon1.png'
import commonEmptyImg from './images/common/empty_img.png'
import commonCalendarIcon from './images/common/calendar_icon.png'
import commonPaymentIcon1 from './images/common/payment_icon1.png'
import commonPaymentIcon2 from './images/common/payment_icon2.png'
import commonPaymentIcon3 from './images/common/payment_icon3.png'
import commonCodeBgImg from './images/common/code_bg.png'
import commonCsYesIcon from './images/common/service_yes_icon.png'
import commonBitIcon from './images/common/icon_btc_defult.svg'

// 登录注册页
import loginRegUserIcon from './images/loginReg/icon_user.png'
import loginRegKeyIcon from './images/loginReg/icon_key.png'
import loginRegLockIcon from './images/loginReg/icon_lock.png'
import loginRegPhoneIcon from './images/loginReg/icon_phone.png'
import loginRegBgImg from './images/loginReg/bg.png'

// 首页
import homeMsgIcon from './images/home/msg_icon.png'
import homeNoticeIcon from './images/home/notice_icon.png'
import homeBalanceBgImg from './images/home/balance_bg.png'
import homeDealIcon from './images/home/deal_icon.png'
import homeSellIcon from './images/home/sell_icon.png'
import homeAmountIcon from './images/home/amount_icon.png'
import homeAmountIcon1 from './images/home/amount_icon1.png'
import homeAmountIcon2 from './images/home/amount_icon2.png'
import homePopBgImg from './images/home/pop_bg.png'
import homePrevIcon from './images/home/prev_icon.png'
import homeNextIcon from './images/home/next_icon.png'
import homeCloseIcon from './images/home/close_icon.png'
import homeDoubleRightArrowIcon from './images/home/double_right_arrow_icon.png'
import homeBitRechargeIcon from './images/home/bit_recharge_icon.svg'
import homeBitWithdrawIcon from './images/home/bit_withdraw_icon.svg'

// 底部
import footerHomeIcon from './images/footer/home.png'
import footerOrderIcon from './images/footer/order.png'
import footerPaymentIcon from './images/footer/payment.png'
import footerUserIcon from './images/footer/user.png'
import footerHomeActiveIcon from './images/footer/home_active.png'
import footerOrderActiveIcon from './images/footer/order_active.png'
import footerPaymentActiveIcon from './images/footer/payment_active.png'
import footerUserActiveIcon from './images/footer/user_active.png'
import footerScanIcon from './images/footer/scan.png'

// 交易大厅
import tradingHallBalanceBgImg from './images/tradingHall/balance_bg.png'
import tradingHallServiceIcon from './images/tradingHall/white_service_icon.png'
import tradingHallAmountIcon from './images/tradingHall/amountIcon.png'

// 个人中心
import myRadianBgImg from './images/my/radian_bg.png'
import myUnCertificationIcon from './images/my/un_certification_icon.png'
import myCertificationIcon from './images/my/certification_icon.png'
import myHelpIcon from './images/my/help_icon.png'
import myAboutIcon from './images/my/about_icon.png'
import myIcon1 from './images/my/icon1.png'
import myIcon2 from './images/my/icon2.png'
import myIcon3 from './images/my/icon3.png'
import myIcon4 from './images/my/icon4.png'
import myIcon5 from './images/my/icon5.png'
import myIcon6 from './images/my/icon6.png'
import myIcon7 from './images/my/icon7.png'
import myHelpContent1 from './images/my/help_content1.png'
import myHelpContent2 from './images/my/help_content2.png'
import myHelpContent3 from './images/my/help_content3.png'
import myHelpContent4 from './images/my/help_content4.png'
import mySolidDownIcon from './images/my/solid_down_icon.png'
import myIdentityCardBgImg from './images/my/identity_card_bg.png'
import myIdentityCardBgImg1 from './images/my/identity_card_bg1.png'
import myUploadBtnImg from './images/my/upload_btn.png'
import myRecognitionImg from './images/my/recognition_img.png'
import myAvatarSelected from './images/my/avatarSelected.png'

// 收付款
import paymentDoublArrowIcon from './images/payment/double_arrow_icon.png'
import paymentStatusBgImg from './images/payment/status_bg.png'
import paymentAddIcon from './images/payment/add.png'

// 交易流程详情页面
import orderDetailsProcessIcon from './images/order/process_icon.png'
import orderDetailsProcessActiveIcon from './images/order/process_active_icon.png'
import orderBitFailIcon from './images/order/icon_tips.svg'

export default {
    // 公用
    commonServiceIcon,
    commonCopyIcon,
    commonTransIcon,
    commonArrowDownIcon,
    commonColorArrowDownIcon,
    commonEmptyImg,
    commonCalendarIcon,
    commonPaymentIcon1,
    commonPaymentIcon2,
    commonPaymentIcon3,
    commonCodeBgImg,
    commonCsYesIcon,
    commonBitIcon,
    // 登录注册页
    loginRegUserIcon,
    loginRegKeyIcon,
    loginRegLockIcon,
    loginRegPhoneIcon,
    loginRegBgImg,
    // 首页
    homeMsgIcon,
    homeNoticeIcon,
    homeBalanceBgImg,
    homeDealIcon,
    homeSellIcon,
    homeAmountIcon,
    homeAmountIcon1,
    homeAmountIcon2,
    homePopBgImg,
    homePrevIcon,
    homeNextIcon,
    homeCloseIcon,
    homeDoubleRightArrowIcon,
    homeBitRechargeIcon,
    homeBitWithdrawIcon,
    // 底部
    footerHomeIcon,
    footerOrderIcon,
    footerPaymentIcon,
    footerUserIcon,
    footerHomeActiveIcon,
    footerOrderActiveIcon,
    footerPaymentActiveIcon,
    footerUserActiveIcon,
    footerScanIcon,
    // 交易大厅
    tradingHallBalanceBgImg,
    tradingHallServiceIcon,
    tradingHallAmountIcon,
    // 个人中心
    myRadianBgImg,
    myUnCertificationIcon,
    myCertificationIcon,
    myHelpIcon,
    myAboutIcon,
    myIcon1,
    myIcon2,
    myIcon3,
    myIcon4,
    myIcon5,
    myIcon6,
    myIcon7,
    myHelpContent1,
    myHelpContent2,
    myHelpContent3,
    myHelpContent4,
    mySolidDownIcon,
    myIdentityCardBgImg,
    myIdentityCardBgImg1,
    myUploadBtnImg,
    myRecognitionImg,
    myAvatarSelected,
    // 收付款
    paymentDoublArrowIcon,
    paymentStatusBgImg,
    paymentAddIcon,
    // 交易流程详情页面
    orderDetailsProcessIcon,
    orderDetailsProcessActiveIcon,
    orderBitFailIcon
}
